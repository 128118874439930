.allowed{
  cursor: pointer;
}
.not-allowed{
  cursor: not-allowed;
}
.circle-color{
  height: 20px;
  width: 100%;
}
.modal-header{
  justify-content: space-between;
}
.modal-header .close{
  padding: 0rem 0.4rem !important;
  border: none;
  outline: none;
  background: var(--color-blue-light);
  color: white;
  font-size: 1.5rem;
}
.download-tests .container {
  display: flex;

  align-items: flex-end;
  justify-content: flex-end;
}

.download-tests .btn {
  margin-right: 20px; /* Optional: Adjust spacing from the right edge */
}
